import Vue from "vue";

import "../css/index.css";

import "./inline-attachment";
import "./codemirror-4.inline-attachment";

import App from "./App.js";
import ClubApi from "./common/api.service.js";
import { pluralize } from "./common/utils.js";
import { handleCommentThreadCollapseToggle } from "./common/comments.js";

Vue.component("post-upvote", () => import("./components/PostUpvote.vue"));
Vue.component("post-bookmark", () => import("./components/PostBookmark.vue"));
Vue.component("post-subscription", () => import("./components/PostSubscription.vue"));
Vue.component("post-rsvp", () => import("./components/PostRSVP.vue"));
Vue.component("comment-upvote", () => import("./components/CommentUpvote.vue"));
Vue.component("user-tag", () => import("./components/UserTag.vue"));
Vue.component("user-avatar-input", () => import("./components/UserAvatarInput.vue"));
Vue.component("sidebar-toggler", () => import("./components/SidebarToggler.vue"));
Vue.component("input-length-counter", () => import("./components/InputLengthCounter.vue"));
Vue.component("friend-button", () => import("./components/FriendButton.vue"));
Vue.component("comment-scroll-arrow", () => import("./components/CommentScrollArrow.vue"));
Vue.component("comment-markdown-editor", () => import("./components/CommentMarkdownEditor.vue"));
Vue.component("post-filter", () => import("./components/PostFilter.vue"));

// Since our pages have user-generated content, any fool can insert "{{" on the page and break it.
// We have no other choice but to completely turn off template matching and leave it on only for components.
const noDelimiter = { replace: function () {} };

new Vue({
    el: "#app",
    delimiters: [noDelimiter, noDelimiter], // disable templates
    created() {
        App.onCreate();
    },
    mounted() {
        App.onMount();
    },
    data: {
        shownWindow: null,
        showMore: false,
        isPostLinkPasswordVisible: (document.getElementById('id_access_mode_2') && document.getElementById('id_access_mode_2').checked == true),
        attachedPosts: document.getElementById('attached_posts_json') ? JSON.parse(document.getElementById('attached_posts_json').innerHTML) : [
            {
                deleted: false,
                slug: "",
                title: "",
                prefix: "",
                description: "",
            },
        ],
        attachedPostsFormValue: document.getElementById('attached_posts_form_value') ? document.getElementById('attached_posts_form_value').innerHTML : ""
    },
    methods: {
        updateAttachedPostsFormValue() {
            this.attachedPostsFormValue = "";
            for (let i in this.attachedPosts) {
                if (!this.attachedPosts[i].deleted && this.attachedPosts[i].slug != "") {
                    if (this.attachedPostsFormValue) this.attachedPostsFormValue += ",";
                    this.attachedPostsFormValue += this.attachedPosts[i].slug;
                }
            }
        },
        attachPost(index, object) {
            if (this.attachedPosts.length >= 50) {
                return;
            }
            if (index >= 0 && object && index < this.attachedPosts.length) {
                this.attachedPosts[index]['slug'] = object['slug'];
                this.attachedPosts[index]['title'] = object['title'];
                this.attachedPosts[index]['prefix'] = object['prefix'];
                this.attachedPosts[index]['description'] = object['description'];
            }
            this.updateAttachedPostsFormValue();
            this.attachedPosts.push({
                deleted: false,
                slug: "",
                title: "",
                prefix: "",
                description: "",
            })
        },
        detachPost(index) {
            if (index >= this.attachedPosts.length) {
                return;
            }
            this.attachedPosts[index]['deleted'] = true;
            this.updateAttachedPostsFormValue();
        },
        toggleCommentThread(event) {
            const comment = event.target.closest(".reply") || event.target.closest(".comment");
            if (comment === null) {
                return;
            }

            // toggle thread visibility
            const collapseItems = comment.querySelectorAll(".thread-collapse-toggle");
            for (let i = 0; i < collapseItems.length; i++) {
                const isVisible = window.getComputedStyle(collapseItems[i]).display !== "none";
                collapseItems[i].style.display = isVisible ? "none" : null;
            }

            // show/hide placeholder with thread length
            const collapseStub =
                comment.querySelector(".comment-collapse-stub") || comment.querySelector(".reply-collapse-stub");
            const wasCollapsed = collapseStub.style.display !== "block";
            collapseStub.style.display = wasCollapsed ? "block" : "none";
            const threadLength = comment.querySelectorAll(".reply").length + 1;
            const pluralForm = pluralize(threadLength, ["комментарий", "комментария", "комментариев"]);
            collapseStub.querySelector(".thread-collapse-length").innerHTML = `${threadLength} ${pluralForm}`;

            handleCommentThreadCollapseToggle(wasCollapsed, comment.id);
            // scroll back to comment if it's outside of the screen
            const commentPosition = comment.getBoundingClientRect();
            if (commentPosition.top < 0) {
                window.scrollBy(0, commentPosition.top);
            }
        },
        showReplyForm(commentId, username, withSelection) {
            // First, hide all other reply forms
            const replyForms = document.querySelectorAll(".reply-form-form");
            for (let i = 0; i < replyForms.length; i++) {
                // replyForms[i].removeEventListener('keydown', handleCommentHotkey); // FIXME???
                replyForms[i].style.display = "none";
            }

            // Then show one for commentId
            const commentReplyForm = document.getElementById("reply-form-" + commentId);
            // commentReplyForm.addEventListener('keydown', (event) => handleCommentHotkey(event, commentReplyForm));  // FIXME???
            commentReplyForm.style.display = null;

            // Define helper function
            function appendMarkdownTextareaValue(textarea, value) {
                textarea.focus(); // on mobile
                textarea.value = textarea.value + value;

                // On mobile the next element sibling is undefined
                if (textarea.nextElementSibling) {
                    const codeMirrorEditor =
                        textarea.nextElementSibling.CodeMirror ||
                        textarea.nextElementSibling.querySelector(".CodeMirror").CodeMirror;
                    if (codeMirrorEditor !== undefined) {
                        codeMirrorEditor.setValue(codeMirrorEditor.getValue() + value);
                        codeMirrorEditor.focus();
                        codeMirrorEditor.setCursor(codeMirrorEditor.lineCount(), 0);
                    }
                }
            }

            // Add username to reply
            const commentReplyTextarea = commentReplyForm.querySelector("textarea");
            if (username !== null && username !== "") {
                appendMarkdownTextareaValue(commentReplyTextarea, "@" + username + ", ");
            }

            // Add selected text as quote
            if (withSelection) {
                const selectedText = window.getSelection().toString();
                if (selectedText !== null && selectedText !== "") {
                    appendMarkdownTextareaValue(commentReplyTextarea, "\n> " + selectedText + "\n\n");
                }
            }

            appendMarkdownTextareaValue(commentReplyTextarea, "");
        },


        fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        },
        copyTextToClipboard(text) {
            if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(function() {
                console.log('Async: Copying to clipboard was successful!');
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });
        }
    },
});
